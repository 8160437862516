import { Helmet } from '@/components'
import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useLayoutEffect, useState } from 'react'
import { Footer, Header, SEO, useI18next } from '../components'
import Title from '../components/Title'

export default (props) => {
  const theme = useTheme()
  const { direction } = theme

  const {} = useTranslation()
  const { t, languages, changeLanguage, language } = useI18next()
  const pageLocale = JSON.parse(props.data.locales.edges[0].node.data)
  const commonLocale = JSON.parse(props.data.commonLocale.data)
  const seoLocale = JSON.parse(props.data.seoLocale.data)
  const [expand, setExpand] = useState(true)
  const [isPc, setIsPC] = useState<null | boolean>(null)
  const _isPc = useMediaQuery('@media (min-width: 600px)')

  const growthList = [
    {
      title: pageLocale.Reliability,
      content: `${pageLocale.ReliabilityDesc}`,
    },
    {
      title: pageLocale.Flexibility,
      content: pageLocale.FlexibilityDesc,
    },
    {
      title: pageLocale.Innovation,
      content: pageLocale.InnovationDesc,
    },
  ]

  const restSx =
    !isPc && !expand
      ? {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          '-webkit-line-clamp': '12',
          '-webkit-box-orient': 'vertical',
        }
      : {}

  useLayoutEffect(() => {
    setIsPC(_isPc)
    setExpand(_isPc ? true : false)
  }, [_isPc])

  useLayoutEffect(() => {
    document.querySelector('.banner')?.scrollIntoView({})
  }, [])
  return (
    <>
      <SEO
        title={seoLocale.about.title}
        description={seoLocale.about.description}
        pageLocale={pageLocale}
      />
      <Helmet>
        <meta
          name='keywords'
          content='iMile delivery services, iMile delivery, Ecommerce delivery services, last mile service providers, e-commerce and logistics company UAE'
        />

        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='iMile Delivery' />
        <meta property='og:url' content='https://www.imile.com/about' />
        <meta
          property='og:image'
          content='https://www.imile.com/static/7e78def03f159b35806c4d2e0f808104/56c4d/aboutus-banner.webp'
        />
        <meta property='og:title' content='About iMile Delivery' />
        <meta
          property='og:description'
          content='iMile Delivery Services is an emerging start-up in the Middle East that offers eCommerce and Logistics Solutions. Seamless delivery experience that you can trust.'
        />
      </Helmet>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />

      {/* banner */}
      <Box
        className='banner'
        sx={{
          position: 'relative',
          '.bg-img': {
            height: ['100vh', '100vh', '650px', '790px'],
            width: 1,
          },
        }}
      >
        {isPc ? (
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/aboutus/banner.png'
            alt='About iMile Delivery'
            className='bg-img'
          />
        ) : (
          //   <StaticImage
          //     src='../../static/images/aboutus/banner.png'
          //     alt='About iMile Delivery'
          //     objectFit='cover'
          //     loading='lazy'
          //     placeholder='blurred'
          //     className='bg-img'
          //   />
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/aboutus/banner_mobile.png'
            alt='About iMile Delivery'
            className='bg-img'
          />
          //   <StaticImage
          //     src='../../static/images/aboutus/banner_mobile.png'
          //     alt='About iMile Delivery'
          //     objectFit='cover'
          //     loading='lazy'
          //     placeholder='blurred'
          //     className='bg-img'
          //   />
        )}

        <Box
          sx={{
            position: 'absolute',
            top: ['25%', '25%', '50%'],
            transform: 'translate(0, -40%)',
            right: ['16px', '34px', '80px'],
            color: '#fff',
            textAlign: 'right',
            marginInlineStart: 'auto',
            '.tit': {
              marginInlineStart: direction === 'ltr' ? 'auto' : 'unset',
              fontSize: [24, 30, 35, 35, 40],
              fontWeight: 600,
              mb: '10px',
              whiteSpace: 'pre-line',
            },
            '.desc': {
              fontSize: [14, 18, 22, 22, 28],
              whiteSpace: 'pre-line',
            },
          }}
        >
          <h2 className='tit'>{pageLocale.Slogan}</h2>
          <div className='desc'>{pageLocale.SloganDesc}</div>
        </Box>
      </Box>

      {/* our story */}
      <Box
        sx={{
          px: 'var(--page-px)',
          maxWidth: 'var(--page-width)',
          margin: '0 auto',
        }}
      >
        <Title title={pageLocale.title1} component='h1' />
        <Box
          sx={{
            display: {
              md: 'flex',
            },
            position: 'relative',
            '.i-img': {
              float: ['left', 'left', undefined],
              width: ['173px', '280px', '317px'],
              minWidth: ['', '250px'],
              height: ['254px', '350px', '448px'],
              display: 'block',
              margin: ['0 10px 10px 0', '0 10px 10px 0', 0],
            },
          }}
        >
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/boss.png'
            alt='Rita Huang - CEO'
            className='i-img'
          />
          {/* <StaticImage
            src='../../static/images/aboutus/boss.png'
            alt='Rita Huang - CEO'
            objectFit='cover'
            loading='lazy'
            placeholder='blurred'
            className='i-img'
          /> */}
          <Box
            sx={{
              display: {
                md: 'flex',
              },
              flexDirection: 'column',
              textAlign: 'justify',
              color: '#000417',
              maxWidth: ['923px'],
              marginInlineStart: ['0px', '10px', '30px'],
              '.MuiBox-root:not(:first-of-type)': {
                mt: ['5px', '5px', '20px'],
              },
              '.story-content': {
                fontSize: [12, 14, 16, 16],
                lineHeight: ['24px', '24px', '26px', '28px'],
                color: '#000417',
              },
              ...restSx,
            }}
          >
            {!expand && <span className='story-content'>{pageLocale.summary}</span>}
            {expand &&
              pageLocale.content1.map((o, i) => {
                return (
                  <Box key={i} className='story-content'>
                    {o}
                  </Box>
                )
              })}
            {!expand && !isPc && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  color: '#095ef7',
                  px: '3px',
                  bgcolor: '#fff',
                }}
                onClick={() => {
                  setExpand(!expand)
                }}
              >
                ...{commonLocale.readMore}
              </Box>
            )}
            {expand && !isPc && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '-20px',
                  right: 0,
                  color: '#095ef7',
                  px: '3px',
                  bgcolor: '#fff',
                }}
                onClick={() => {
                  setExpand(!expand)
                }}
              >
                {commonLocale.readLess}
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {/* iMile's Growth */}
      <Box
        sx={{
          // pt: ['20px', '20px', '40px'],
          pb: 'var(--section-spacing-y)',
          px: 'var(--page-px)',
          m: '0 auto',
          maxWidth: [
            '100%',
            'calc(var(--page-width) - 300px)',
            'calc(var(--page-width) - 300px)',
            'calc(var(--page-width) - 160px)',
            'calc(var(--page-width) - 300px)',
          ],
        }}
      >
        <Title title={pageLocale.imileGrow} component='h2' />
        {isPc ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline',
              mt: '220px',
              mb: '160px',
            }}
          >
            {pageLocale.content2.map((item, index) => {
              return (
                <Box
                  className='container'
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    flex: 1,
                    flexDirection: index % 2 === 0 ? 'column-reverse' : 'column',
                    alignItems: 'center',
                    maxWidth: '338px',
                    '.dot': {
                      position: 'relative',
                      width: '23px',
                      height: '23px',
                      background: '#fff',
                      borderRadius: '100%',
                      border: '7px solid #095ef7',
                      '&:after':
                        index <= pageLocale.content2.length - 1
                          ? {
                              content: '""',
                              position: 'absolute',
                              left: '15px',
                              top: '3px',
                              display: 'inline-block',
                              width: '148px',
                              maxWidth: '148px',
                              height: '2px',
                              background: '#095ef7',
                            }
                          : {},

                      '&:before':
                        index % 2 === 0
                          ? {
                              content: '""',
                              position: 'absolute',
                              left: '-15px',
                              top: '-26px',
                              display: 'inline-block',
                              width: '40px',
                              maxWidth: '40px',
                              height: '2px',
                              background: '#095ef7',
                              transform: 'rotate(90deg)',
                            }
                          : {
                              content: '""',
                              position: 'absolute',
                              left: '-15px',
                              top: '34px',
                              display: 'inline-block',
                              width: '40px',
                              maxWidth: '40px',
                              height: '2px',
                              background: '#095ef7',
                              transform: 'rotate(90deg)',
                            },
                    },
                    '.content': {
                      position: 'absolute',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      my: '80px',
                      '.tit': {
                        fontSize: [26, 26, 26, 30],
                        fontWeight: 600,
                        color: '#095ef7',
                      },
                      '.desc': {
                        fontSize: [14, 14, 14, 16],
                        color: '#5b5c73',
                        width: [212, 212, 220, 280],
                      },
                    },
                  }}
                >
                  <div className='dot'></div>
                  <div className='content'>
                    <div className='tit'>{item.split('&&')[0]}</div>
                    <div className='desc'>{item.split('&&')[1]}</div>
                  </div>
                </Box>
              )
            })}
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '800px',
              mb: '160px',
            }}
          >
            {pageLocale.content2.map((item, index) => {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    position: 'relative',
                    // flexDirection: index % 2 === 0 ? 'row-reverse' : 'row',
                    '.dot': {
                      position: 'relative',
                      width: '23px',
                      height: '23px',
                      background: '#fff',
                      borderRadius: '100%',
                      border: '7px solid #095ef7',
                      '&:after':
                        index < pageLocale.content2.length - 1
                          ? {
                              content: '""',
                              position: 'absolute',
                              left: '4px',
                              top: '11px',
                              display: 'inline-block',
                              height: '148px',
                              maxHeight: '148px',
                              width: '2px',
                              background: '#095ef7',
                            }
                          : {},
                      '&:before':
                        index % 2 === 0
                          ? {
                              content: '""',
                              position: 'absolute',
                              left: -57,
                              top: 3,
                              display: 'inline-block',
                              width: 54,
                              maxWidth: 54,
                              height: '2px',
                              background: '#095ef7',
                            }
                          : {
                              content: '""',
                              position: 'absolute',
                              left: 11,
                              top: 3,
                              display: 'inline-block',
                              width: 54,
                              maxWidth: 54,
                              height: '2px',
                              background: '#095ef7',
                            },
                    },
                    '.content': {
                      position: 'absolute',
                      left: index % 2 === 0 ? -168 : 40,
                      textAlign: 'center',
                      minWidth: 140,
                      maxWidth: ['150px', '200px'],
                      '.tit': {
                        fontSize: 16,
                        fontWeight: 600,
                        color: '#095ef7',
                      },
                      '.desc': {
                        fontSize: 12,
                        color: '#5b5c73',
                      },
                    },
                  }}
                >
                  <div className='dot'></div>
                  <div className='content'>
                    <div className='tit'>{item.split('&&')[0]}</div>
                    <div className='desc'>{item.split('&&')[1]}</div>
                  </div>
                </Box>
                // <Box
                //   className='container'
                //   sx={{
                //     position: 'relative',
                //     display: 'flex',
                //     flex: 1,
                //     flexDirection: index % 2 === 0 ? 'column-reverse' : 'column',
                //     alignItems: 'center',
                //     maxHeight: '338px',
                //     '.dot': {
                //       position: 'relative',
                //       width: '23px',
                //       height: '23px',
                //       background: '#fff',
                //       borderRadius: '100%',
                //       border: '7px solid #095ef7',
                //       '&:after':
                //         index < pageLocale.content2.length - 1
                //           ? {
                //               content: '""',
                //               position: 'absolute',
                //               left: '15px',
                //               top: '3px',
                //               display: 'inline-block',
                //               width: '148px',
                //               maxWidth: '148px',
                //               height: '2px',
                //               background: '#095ef7',
                //             }
                //           : {},

                //       '&:before':
                //         index % 2 === 0
                //           ? {
                //               content: '""',
                //               position: 'absolute',
                //               left: '-15px',
                //               top: '-26px',
                //               display: 'inline-block',
                //               width: '40px',
                //               maxWidth: '40px',
                //               height: '2px',
                //               background: '#095ef7',
                //               transform: 'rotate(90deg)',
                //             }
                //           : {
                //               content: '""',
                //               position: 'absolute',
                //               left: '-15px',
                //               top: '34px',
                //               display: 'inline-block',
                //               width: '40px',
                //               maxWidth: '40px',
                //               height: '2px',
                //               background: '#095ef7',
                //               transform: 'rotate(90deg)',
                //             },
                //     },
                //     '.content': {
                //       position: 'absolute',
                //       display: 'flex',
                //       flexDirection: 'column',
                //       alignItems: 'center',
                //       textAlign: 'center',
                //       my: '80px',
                //       '.tit': {
                //         fontSize: [26, 26, 26, 30],
                //         fontWeight: 600,
                //         color: '#095ef7',
                //       },
                //       '.desc': {
                //         fontSize: [14, 14, 14, 16],
                //         color: '#5b5c73',
                //         width: [212, 212, 220, 280],
                //       },
                //     },
                //   }}
                // >
                //   <div className='dot'></div>
                //   <div className='content'>
                //     <div className='tit'>{item.split('&&')[0]}</div>
                //     <div className='desc'>{item.split('&&')[1]}</div>
                //   </div>
                // </Box>
              )
            })}
          </Box>
        )}
      </Box>

      {/* Our company principles */}
      <Box
        sx={{
          px: 'var(--page-px)',
          maxWidth: 'var(--page-width)',
          margin: '0 auto',
        }}
      >
        <Title
          component='h2'
          title={pageLocale.CompanyPrinciples}
          maxWidth={['471px']}
          sx={{ pt: 'var(--section-spacing-y)', lineHeight: 1, paddingInlineStart: 0 }}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
            mb: ['30px', '120px'],
          }}
        >
          {growthList.map((item, index) => {
            return (
              <Box
                sx={{
                  position: 'relative',
                  textAlign: 'center',
                  flex: [, , 1],
                  mx: ['5px', '5px', '10px', '15px'],
                  maxWidth: ['255px', '380px'],
                  height: ['231px', '362px'],
                  color: '#fff',
                  background: '#095ef7',
                  borderRadius: '32px',
                  mb: ['30px'],
                  ':after': {
                    content: '""',
                    position: 'absolute',
                    top: '10px',
                    left: '-10px',
                    width: '100%',
                    height: '100%',
                    border: '4px solid #fff303',
                    borderRadius: '32px',
                  },
                  p: ['30px 24px', '60px 32px', '60px 32px', '40px 22px', '50px 30px'],
                  '.tit': {
                    fontSize: [19, 26, 26, 26, 30],
                    fontWeight: 600,
                    mb: ['20px', '32px', '32px', '20px', '32px'],
                    whiteSpace: 'pre-line',
                  },
                  '.desc': {
                    fontSize: [14, 16, 19, 20],
                    whiteSpace: 'pre-line',
                  },
                }}
              >
                <div className='tit'>{item.title}</div>
                <div className='desc'>{item.content}</div>
              </Box>
            )
          })}
        </Box>
      </Box>

      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["about"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
  }
`
